<template>
  <b-col md="12" lg="8" xl="6">
    <b-card-group class="align-items-center bg-white h-100">
      <b-card no-body class="border-0 rounded-0 py-0">
        <b-card-body class="pt-0 card-body-info">
          <b-form
            class="register-email review-contact-form form-inputs"
            novalidate
            @submit.prevent="onSubmitRegister"
          >
            <h3 class="title pb-md-3 pb-xxl-5 mb-xxl-2 text-center">
              お問合せ
            </h3>
            <transition-alert>
              <template v-if="msgErrors.length">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="danger"
                >
                  <span
                    class="d-block"
                    v-for="(msg, index) in msgErrors"
                    :key="index"
                    >{{ msg }}</span
                  >
                </b-alert>
              </template>
            </transition-alert>
            <transition-alert>
              <template v-if="msgSuccess">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="success"
                >
                  {{ msgSuccess }}
                </b-alert>
              </template>
            </transition-alert>
            <b-input-group class="mb-3 mb-xxl-4 pb-xxl-3 mt-3 input-group-info mt-md-0">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0 py-0"
                  ><span class="pr-1">お名前</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email input-form">
                {{ formContact && formContact.name ? formContact.name : '' }}
              </div>
            </b-input-group>
            <b-input-group class="mb-3 mb-xxl-4 pb-xxl-3 mt-3 input-group-info mt-md-0">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0 py-0"
                  ><span class="pr-1">メールアドレス</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email group-password input-form">
                {{ formContact && formContact.email ? formContact.email : '' }}
              </div>
            </b-input-group>
            <b-input-group class="mb-4 pb-2 pb-md-0 mb-xxl-4 pb-xxl-3 input-group-info align-items-baseline">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0 py-0"
                  ><span class="pr-2 pr-lg-4">お問合せ内容</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email group-password input-form">
                {{ selected || '' }}
              </div>
            </b-input-group>
            <b-input-group class="mb-4 pb-2 pb-md-3 mb-xxl-4 pb-xxl-3 input-group-info align-items-baseline">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0 py-0"
                  ><span class="pr-1">お問い合わせ詳細</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email group-password input-form">
                {{ formContact && formContact.message ? formContact.message : '' }}
              </div>
            </b-input-group>

            <p class="text-right d-m-flex pt-3 mt-2">
              <b-link class="btn btn-outline-secondary" @click="redirectPrevStep">戻る</b-link>
              <b-button
                class="primary px-4"
                type="submit"
                :disabled="isSubmitted"
                >送信する
                <esports-loading-button
                  v-if="isSubmitted"
                  width="30px"
                  height="30px"
                  outerBorder="#333"
                  innerBorder="#eee"
              /></b-button>
            </p>
          </b-form>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
import { dismissCount } from "@/mixins";
export default {
  name: "ContactReviewInfoUser",
  props: {
    formContactData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitted: false,
      msgErrors: [],
      msgSuccess: null,
      formContact: {},
      selected: '',
      options: [
        { value: 0, text: "選手登録希望" },
        { value: 1, text: "取材について" },
        { value: 2, text: "広告掲載について" },
        { value: 3, text: "その他" },
      ],
    };
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mixins: [dismissCount],
  mounted() {
    if(this.formContactData) {
      this.formContact = { ...this.formContactData };
      this.selected = this.options.filter(item => item.value === this.formContact.content);
      if(this.selected.length) {
        this.selected = this.selected[0].text;
      }
    }
  },
  methods: {
    async onSubmitRegister() {

      this.isSubmitted = true;
      this.formContact.isValidate = false;

      const result = await this.$store
        .dispatch("auth/contactAdmin", this.formContact)
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      this.isSubmitted = false;

      if (result) {
        this.msgErrors = [];

        this.$emit("activeComponent", {
          user: this.formContact,
          name: "ContactTopPage",
        });
      }
    },
    redirectPrevStep() {
      this.$emit("activeComponent", {
          user: this.formContact,
          name: "ContactInfoUser",
      });
    }
  },
};
</script>