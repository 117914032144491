<template>
  <b-col md="12" lg="8" xl="6">
    <b-card-group class="align-items-center bg-white h-100">
      <b-card no-body class="border-0 rounded-0">
        <b-card-body class="pt-0 card-body-info">
          <b-form
            class="register-email form-inputs"
            novalidate
            @submit.prevent="onSubmitRegister"
          >
            <h3 class="title pb-md-3 pb-xxl-4 text-center">お問合せ</h3>
            <transition-alert>
              <template v-if="msgErrors.length">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="danger"
                >
                  <span
                    class="d-block"
                    v-for="(msg, index) in msgErrors"
                    :key="index"
                    >{{ msg }}</span
                  >
                </b-alert>
              </template>
            </transition-alert>
            <transition-alert>
              <template v-if="msgSuccess">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="success"
                >
                  {{ msgSuccess }}
                </b-alert>
              </template>
            </transition-alert>
            <p class="hint">※は必須入力となります。</p>
            <b-input-group class="mb-1 mb-md-0 mt-3 input-group-info mt-md-0">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-1">お名前</span
                  ><span class="start">※</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email">
                <b-form-input
                  class="text-truncate"
                  type="text"
                  maxLength="60"
                  v-model="formContact.name"
                  autocomplete="off"
                />
                <span class="chars">全角60文字以内</span>
              </div>
            </b-input-group>
            <b-input-group class="mb-1 mb-md-0 mt-3 input-group-info mt-md-0">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-1">メールアドレス</span
                  ><span class="start">※</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email group-password">
                <b-form-input
                  class="text-truncate"
                  type="text"
                  v-model="formContact.email"
                  autocomplete="off"
                />
                <span class="chars">半角英数</span>
              </div>
            </b-input-group>
            <b-input-group
              class="
                mb-4
                pb-2 pb-md-0
                mb-md-4
                input-group-info
                align-items-baseline
              "
            >
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-2 pr-lg-4"
                    >お問い合わせ内容</span
                  ></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email group-password">
                <!-- <b-form-input
                  class="text-truncate"
                  type="text"
                  v-model="formContact.content"
                  minLength="8"
                  maxLength="32"
                  autocomplete="off"
                />
                <span class="chars">半角英数8文字以上32文字以内</span> -->
                <b-form-select
                  v-model="formContact.content"
                  class="form-control"
                  :options="options"
                ></b-form-select>
              </div>
            </b-input-group>
            <b-input-group
              class="
                mb-4
                pb-2 pb-md-0
                mb-md-0
                input-group-info
                align-items-baseline
              "
            >
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-1">お問い合わせ詳細</span
                  ><span class="start">※</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email group-password">
                <b-form-textarea
                  v-model="formContact.message"
                  rows="3"
                  no-resize
                ></b-form-textarea>
              </div>
            </b-input-group>

            <p class="text-right d-m-flex pt-3 mt-2">
              <b-button
                class="btn btn-outline-secondary"
                @click="resetFormContact"
                >キャンセル</b-button
              >
              <b-button
                class="primary px-4"
                type="submit"
                :disabled="isSubmitted"
                >確認する
                <esports-loading-button
                  v-if="isSubmitted"
                  width="30px"
                  height="30px"
                  outerBorder="#333"
                  innerBorder="#eee"
              /></b-button>
            </p>
          </b-form>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
import { dismissCount } from "@/mixins";
import { mapGetters } from "vuex";

export default {
  name: "ContactInfoUser",
  props: {
    formContactData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitted: false,
      msgErrors: [],
      msgSuccess: null,
      options: [
        { value: 0, text: "選手登録希望" },
        { value: 1, text: "取材について" },
        { value: 2, text: "広告掲載について" },
        { value: 3, text: "その他" },
      ],
      formContact: {
        email: "",
        name: "",
        content: 0,
        message: "",
        isValidate: true,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mixins: [dismissCount],
  async mounted() {
    if(!this.formContactData && this.user) {
      await this.getMyPage();
      this.formContact.email = this.user.email;
      this.formContact.name = this.user.username;
    }
    if (this.formContactData) this.formContact = { ...this.formContactData };
  },
  methods: {
    async getMyPage() {
      const result = await this.$store
        .dispatch("user/getMyPage")
        .catch((err) => {
          console.log(err);
        });

      if(result) {
        this.$store.commit('auth/updateUserProfile', result);
      }
    },
    async onSubmitRegister() {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("auth/contactAdmin", this.formContact)
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }

          this.scrollToTop();
        });

      this.isSubmitted = false;

      if (result) {
        this.msgErrors = [];

        this.$emit("activeComponent", {
          user: this.formContact,
          name: "ContactReviewInfoUser",
        });
      }
    },
    resetFormContact() {
      this.formContact.email = this.formContactData
        ? this.formContactData.email
        : "";
        
      this.formContact.name = this.formContactData
        ? this.formContactData.name
        : "";
      this.formContact.content = this.formContactData
        ? this.formContactData.content
        : 0;
      this.formContact.message = this.formContactData
        ? this.formContactData.message
        : "";
      this.scrollToTop();
    },
  },
};
</script>