<template>
  <div class="w-100">
    <b-container
      class="mt-4 mt-xxl-5 login-page register-page contact-form pb-5"
    >
      <b-row class="justify-content-center w-100">
        <component
          @activeComponent="activeComponent"
          :formContactData="formContactData"
          :is="currentTabComponent"
        ></component>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import ContactInfoUser from "./ContactInfoUser";
import ContactReviewInfoUser from "./ContactReviewInfoUser";
import ContactTopPage from "./ContactTopPage";

export default {
  name: "ContactUs",
  title: "contact_us",
  components: {
    ContactInfoUser,
    ContactReviewInfoUser,
    ContactTopPage
  },
  data() {
    return {
      formContactData: null,
      currentTabComponent: ContactInfoUser,
    };
  },
  methods: {
    activeComponent(payload) {
      this.formContactData = payload.user;
      this.currentTabComponent = payload.name;
    },
  },
};
</script>